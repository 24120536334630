<template>
    <div class="restart-container">
        <div class="tabBar">
            <div class="tab-item" :class="{'active':panlIndex == 1}" @click="selectPanl(1)">重启面板</div>
            <div class="tab-item tab-item2" :class="{'active':panlIndex == 2}" @click="selectPanl(2)">开关面板</div>
        </div>
        <template v-if="panlIndex == 1">
            <div class="filter-pane" style="padding-bottom: 10px">

                <div
                        class="filter-panel-item"
                        v-for="(item, key) in restrictConfig"
                        :key="key"
                >
                    <div class="item">
                        <div class="item-self">{{ item.title }}</div>
                        <div class="item-it">
                            <div>定时重启</div>
                            <div>
                                <CSRadio
                                        v-model="item.entranceRestrict"
                                        :items="{ true: '开启', false: '关闭' }"
                                        @click.native="stopTask(item)"
                                />
                            </div>
                            <button
                                    class="btn btn-primary btns"
                                    @click="restartNowBtn(item)"
                                    style="padding: 0;width: 70px;height: 30px"
                            >
                                立即重启
                            </button>
                        </div>
                    </div>
                    <template v-if="item.entranceRestrict">
                        <div class="setUp">
                            <span>每隔</span>
                            <CSSelect style="padding-left: 5px; margin: 0 5px">
                                <select v-model="item.type" :style="{color:item.type === ''?'#999' : '#444'}">
                                    <option value="">请选择</option>
                                    <option
                                            v-for="item in LaskList"
                                            :key="item.taskKey"
                                            :value="item"
                                    >
                                        {{ item.taskDesc }}天
                                    </option>
                                </select>
                            </CSSelect>
                            <span>凌晨03:00点重启</span>
                            <button
                                    class="btn btn-primary btns"
                                    @click="saveConfig(item)"
                                    style="width: 50px; height: 30px; padding: 0;margin: 0 20px"
                            >
                                保存
                            </button>
                        </div>
                    </template>
                </div>
            </div>
        </template>
        <template v-if="panlIndex == 2">
            <div class="filter-pane" style="padding-bottom: 10px;padding-left: 0;padding-right: 0">
                <div class="restartTop">
                    <div class="top-title">
                        墙面广告面板
                    </div>
                    <div class="top-gand">
                        <div class="gand-title">立即关机</div>
                        <!--                        <button class="btn btn-primary">开机</button>-->
                        <button class="btn btn-primary"  @click="shutdown()" disabled >关机</button>
                    </div>
                    <div class="top-gand" style="margin-bottom: 10px">
                        <div class="gand-title">定时开关机</div>
                        <CSRadio

                                    v-model="switchMachine.enable"
                                :items="{ 0:'开启', 1: '关闭' }"
                        />
                        <button class="btn btn-primary" style="margin-left: 20px" @click="saveShutDownSetting()">保存</button>
                    </div>
                    <div class="time-button" style="margin-left: 100px">
                        <div class="timer-bottom" style="margin-bottom: 10px" v-if="switchMachine.enable === 0">

                            <div style="margin-right: 10px">当天关机时间</div>
                            <CSSelect class="reswtart-panel">
                                <el-time-picker
                                        v-model="switchMachine.timeOff"
                                        size="small"
                                        format='HH:mm'
                                        value-format="HH:mm"
                                        placeholder="请选择关机时间">
                                </el-time-picker>
                            </CSSelect>
                        </div>
                        <div class="timer-bottom" style="margin-bottom: 10px" v-if="switchMachine.enable === 0">
                            <div style="margin-right: 10px">次日开机时间</div>
                            <CSSelect class="reswtart-panel">
                                <el-time-picker
                                        v-model="switchMachine.timeOn"
                                        size="small"
                                        format='HH:mm'
                                        value-format="HH:mm"
                                        placeholder="请选择开机时间">
                                </el-time-picker>
                            </CSSelect>
                        </div>

                    </div>
                </div>

            </div>

        </template>
    </div>
</template>

<script>
    import CSRadio from "@/components/common/CSRadio";
    import CSSelect from "@/components/common/CSSelect";
    import {
        queryLaskListUrl,
        queryTaskRegionUrl,
        queryStartUrl,
        setScheduledTaskStopUrl,
        immediateRebootUrl, queryTimingUpDownUrl, timingUpDownUrl, shutdownUrl, disableInspectPointUrl
    } from "@/requestUrl";

    export default {
        created() {
            this.getLaskList();
            this.getShutDown()
            //this.getScheduledTask();
        },
        data() {
            return {
                staffList: {
                    open: false
                },
                metopeList: {
                    open: false
                },
                panlIndex: 1,
                //闸机时间列表
                LaskList: [],
                restrictConfig: [
                    //闸机进
                    {
                        id: 1,
                        entranceRestrict: false,
                        type: "",
                        title: "闸机（进）面板",
                    },
                    //闸机出
                    {
                        id: 2,
                        entranceRestrict: false,
                        type: "",
                        title: "闸机（出）面板",
                    },
                    //meeting会议面板
                    {
                        id: 3,
                        entranceRestrict: false,
                        type: "",
                        title: "会议室面板",
                    },
                    //墙面面板
                    {
                        id: 4,
                        entranceRestrict: false,
                        type: "",
                        title: "墙面广告面板",
                    },
                    //考勤
                    {
                        id: 5,
                        entranceRestrict: false,
                        type: "",
                        title: "员工考勤面板",
                    },
                    //门禁
                    {
                        id: 6,
                        entranceRestrict: false,
                        type: "",
                        title: "楼层门禁",
                    },
                ],
                switchMachine: {
                    enable: 1,
                    timeOn: '',
                    timeOff: '',
                    regionCode: this.$vc.getCurrentRegion().code
                }
            };
        },
        components: {
            CSRadio,
            CSSelect,
        },
        methods: {
            //选择面板
            selectPanl(index) {
                this.panlIndex = index;
            },
            //立即重启
            restartNowBtn(item) {
                this.$fly.get(immediateRebootUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    type: item.id
                }).then((res) => {
                    if (res.code != 0) {
                        return
                    }
                    this.$vc.toast("重启成功");
                })
            },
            saveConfig(item) {
                //请求地址
                switch (item.id) {
                    //面板进
                    case 1: {
                        this.setStart(item);
                        break;
                    }
                    //面板出
                    case 2: {
                        this.setStart(item);
                        break;
                    }
                    //会议室
                    case 3: {
                        this.setStart(item);
                        break;
                    }
                    //前面广告
                    case 4: {
                        this.setStart(item);
                        break;
                    }
                    //员工考勤
                    case 5: {
                        this.setStart(item);
                        break;
                    }
                    //楼层门禁
                    case 6: {
                        this.setStart(item);
                        break;
                    }
                }
            },
            // 立即重启
            shutdown() {
                this.$CSDialog.confirm({
                    title: '提示',
                    message: '确定关机吗?',
                    onConfirm: () => {
                        this.$fly.get(shutdownUrl, {
                            regionCode: this.$vc.getCurrentRegion().code
                        }).then((res) => {
                            if (res.code != 0) {
                                return;
                            }
                            this.$vc.toast("关机成功");
                        });
                    }
                })

            },
            // 获取广告面板定时设置
            getShutDown() {
                this.$fly.get(queryTimingUpDownUrl, {
                    regionCode: this.$vc.getCurrentRegion().code
                }).then((res) => {
                    if (res.code != 0) {
                        return;
                    }
                    if (res.data != null && res.data !== 'undefined ') {
                        this.switchMachine = res.data;
                    }
                });
            },
            // 保存设置
            saveShutDownSetting() {
                this.$fly.post(timingUpDownUrl, {
                    ...this.switchMachine
                }).then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.$vc.toast("保存成功");
                    this.getShutDown()
                });
            },
            //获取闸机时间列表
            getLaskList() {
                this.$fly.get(queryLaskListUrl).then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.LaskList = res.data;
                    this.getScheduledTask();
                });
            },
            //获取已设置的时间
            getScheduledTask() {
                this.$fly
                    .get(queryTaskRegionUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        console.log(res.data);
                        for (var i = 0; i < res.data.length; i++) {
                            if (res.data[i].taskKey) {
                                this.restrictConfig[i].entranceRestrict = true;
                                this.restrictConfig[i].type = this.LaskList.filter((item) => {
                                    return item.taskKey == res.data[i].taskKey;
                                })[0];
                            }
                        }
                    });
            },
            //设置时间
            setStart(item) {
                this.$fly
                    .get(queryStartUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        type: item.id,
                        taskKey: item.type.taskKey,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.$vc.toast("保存成功");
                    });
            },
            //关闭闸机的自动更新
            Stop(item) {
                this.$fly
                    .get(setScheduledTaskStopUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        type: item.id,
                        taskKey: item.type.taskKey,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        item.type = "";
                        this.$vc.toast("重启已关闭");
                    });
            },
            stopTask(item) {
                if (item.entranceRestrict) {
                    return;
                }
                switch (item.id) {
                    //面板进
                    case 1: {
                        this.Stop(item);
                        break;
                    }
                    //面板出
                    case 2: {
                        this.Stop(item);
                        break;
                    }
                    //会议室
                    case 3: {
                        this.Stop(item);
                        break;
                    }
                    //前面广告
                    case 4: {
                        this.Stop(item);
                        break;
                    }
                    //员工考勤
                    case 5: {
                        this.Stop(item);
                        break;
                    }
                    //楼层门禁
                    case 6: {
                        this.Stop(item);
                        break;
                    }
                }
            },
        },
    };
</script>

<style scoped>

    .tabBar {
        display: flex;
        margin-bottom: 20px;
    }

    .tab-item {
        width: 150px;
        height: 40px;
        background: rgba(108, 117, 126, 1);
        opacity: 1;
        border-radius: 3px 0px 0px 3px;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
    }

    .tab-item2 {
        border-radius: 0px 3px 3px 0px;
    }

    .tab-item.active {
        background: rgba(0, 182, 148, 1);
    }

    .filter-panel-item {
        margin-bottom: 20px;
    }

    .filter-panel-item .item {
        display: flex;
        align-items: center;
    }

    .item-it {
        display: flex;
        align-items: center;
    }

    .item-it div:nth-child(1) {
        margin-right: 30px;
    }

    .item-it div:nth-child(2) {
        display: flex;
        align-items: center;
        line-height: 21px;
    }

    .item-self {
        width: 100px;
        text-align: left;
        margin-right: 20px;
    }

    .setUp {
        display: flex;
        align-items: center;
        margin-top: 20px;
    }

    .filter-pane {
        min-height: 60px;
        background: #fff;
        box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 10%);
        font-size: 14px;
        padding: 15px 30px;
        color: #000;
        padding-bottom: 0;
        box-sizing: border-box;
        position: sticky;
        top: 0;
        z-index: 100;
    }

    .btns {
        padding-top: 4px;
    }

    .top-title {
        font-size: 14px;
        font-weight: bold;
        height: 20px;
        line-height: 20px;
        color: #000000;
        position: relative;
        padding-left: 9px;
    }

    .restartTop {
        padding-left: 20px;
    }

    .top-title::after {
        content: '';
        position: absolute;
        width: 4px;
        height: 14px;
        background: rgba(0, 182, 148, 1);
        left: 0;
        top: calc(50% - 7px);
    }

    .gand-title {
        font-size: 14px;
        color: #000000;
        height: 20px;
        line-height: 20px;
        margin-right: 30px;

    }

    .top-gand {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .btn {
        margin-right: 20px;
        width: 50px;
        height: 30px;
        box-sizing: border-box;
        line-height: 30px;
        padding: 0;
    }
    .timer-bottom{
        display: flex;
        align-items: center;
    }
</style>
